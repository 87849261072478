import Vue from "vue";

const alertsModule = {
    state: {
        formData: {
            name: '',
            triggers: [],
            configParameters: {
                favorite: false,
                key: null,
                nbDays: null,
                serviceId: null
            },
            equipments: [],
            tasks: [
                {
                    type: "parallel_tasks",
                    delay: 0,
                    nb_tries: 0,
                    tasks: [
                        {
                            type: "sms",
                            parameters: {
                                dest: []
                            }
                        },
                        {
                            type: "email",
                            parameters: {
                                dest: []
                            }
                        }
                    ]
                }
            ],
            active: null
        }
    },
    mutations: {
        updateAlertsScenario(state, data) {
            state.formData.name = data.name;
            state.formData.triggers = data.triggers;
            state.formData.configParameters.favorite = data.configParameters.favorite;
            state.formData.configParameters.key = data.configParameters.key;
            state.formData.configParameters.nbDays = data.configParameters.nbDays;
            state.formData.configParameters.serviceId = data.configParameters.serviceId;
        },
        updateAlertsEquipments(state, data){
            state.formData.equipments = data.equipments;
        },
        updateAlertsTasks(state, {blocIndex, data}){
            if(!state.formData.tasks[blocIndex]){
                Vue.set(state.formData.tasks, blocIndex, {});
            }
            state.formData.tasks[blocIndex] = data.tasks;
        },
        updateAlerts(state, data){
            state.formData.name = data.name;
            state.formData.triggers = data.triggers;
            state.formData.configParameters.favorite = data.configParameters.favorite;
            state.formData.configParameters.key = data.configParameters.key;
            state.formData.configParameters.nbDays = data.configParameters.nbDays;
            state.formData.configParameters.serviceId = data.configParameters.serviceId;
            state.formData.equipments = data.equipments;
            state.formData.tasks = data.tasks;
            state.formData.active = data.active;
        },
        resetFormData(state) {
            state.formData = {
                name: '',
                triggers: [],
                configParameters: {
                    favorite: false,
                    key: null,
                    nbDays: null,
                    serviceId: null
                },
                equipments: [],
                tasks: [
                    {
                        type: "parallel_tasks",
                        delay: 0,
                        nb_tries: 0,
                        tasks: [
                            {
                                type: "sms",
                                parameters: {
                                    dest: []
                                }
                            },
                            {
                                type: "email",
                                parameters: {
                                    dest: []
                                }
                            }
                        ]
                    }
                ],
                active: null
            }
        }
    },
    getters: {
        formData: (state) => state.formData
    },
}

export default alertsModule;