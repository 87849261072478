<template>
  <div>
    <div ref="parametersHeader" class="parameters-header row align-items-center py-4">
      <div class="col-2">
        <parameters-return class="ml-3" :route="route"></parameters-return>
      </div>
      <h1 class="col-8 text-center h-parameters">
         {{ title }}
      </h1>
      <div class="col-2 d-flex justify-content-end"
        v-if="this.$route.name === 'parameters.infrastructures.sensors' || 
          this.$route.name === 'parameters.infrastructures.gateways'">
        <router-link :to="{ name: 'parameters.infrastructures.sensors' }" class="btn" active-class="btn-highlighted">
          <b-button size="lg"
            :class="this.$route.name === 'parameters.infrastructures.sensors' ? 'primary-btn' : 'outline-primary-btn'">
            <b-icon icon="broadcast"></b-icon>
          </b-button>
          <div>CAPTEURS</div>
        </router-link>
        <router-link v-if="gatewaysAccess" :to="{ name: 'parameters.infrastructures.gateways' }" class="btn ml-2" active-class="btn-highlighted">
          <b-button size="lg" 
            :class="this.$route.name === 'parameters.infrastructures.gateways' ? 'primary-btn' : 'outline-primary-btn'">
            <b-icon icon="phone" class="mobile-icon"></b-icon>
          </b-button>
          <div>PASSERELLES</div>
        </router-link>
      </div>
    </div>
    <bread-crumb v-if="this.$route.meta.hasBreadCrumb" :items="items" divider-icon="chevron-right"></bread-crumb>
  </div>

</template>

<script>

import {defineComponent} from "vue";
import BreadCrumb from "@/components/common/nav/breadcrumb/BreadCrumb.vue";
import ParametersReturn from "@/components/common/button/ParametersReturn.vue";
import store from "@/store";

export default defineComponent({
  components: {ParametersReturn, BreadCrumb},
  data() {
    return {
      institutionId: null,
      items: [],
      buildingSelected: null,
      stairSelected: null,
      gatewaysAccess: store.state.user.access.gateways.access
    }
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    route: {
      type: String,
      default: ''
    }
  },
  created() {
    this.fillItems();
  },
  watch: {
    '$route': {
      immediate: true,
      handler() {
        const institutionData = store.state.institution
        const buildingId = this.$route.params.buildingId ?? null;
        const stairId = this.$route.params.stairId ?? null;
        this.buildingSelected = null;
        this.stairSelected = null;

        if (buildingId !== null) {
          const building = institutionData.buildings.find(element => element.id == buildingId)
          if (building) {
            if (stairId) {
              const stair = building.levels.find(element => element.id == stairId)
              if (stair) {
                this.stairSelected = stair.name;
              } else {
                this.$router.push({name: 'NotFound'})
              }
            }
            this.buildingSelected = building.name;
          } else {
            this.$router.push({name: 'NotFound'})
          }
        }

        if (this.items) {
          this.fillItems()
        }
      }
    },
  },
  methods: {
    fillItems() {
      let params = this.$route.params
      this.items = [
        {
          name: store.state.institution.institutionName,
          to: {
            name: "routes.names.dashboard",
          },
          svg: "institution.svg",
          disabled: true,
        },
        {
          name: "Bâtiment " + (this.buildingSelected ? ' - ' + this.buildingSelected : ''),
          to: {
            name: 'parameters.buildings',
            params: {
              institutionId: params.institutionId,
            }
          },
          svg: "buildings.svg",
          active: Object.keys(params).length === 1
        },
        {
          name: "Étages" + (this.stairSelected ? ' - ' + this.stairSelected : ''),
          to: {
            name: 'parameters.stairs',
            params: {
              institutionId: params.institutionId,
              buildingId: params.buildingId,
            }
          },
          svg: "stairs.svg",
          active: Object.keys(params).length === 2,
          disabled: Object.keys(params).length < 2

        },
        {
          name: "Pièces",
          to: {
            name: 'rooms',
            params: {
              institutionId: params.institutionId,
              buildingId: params.buildingId,
              stairId: params.stairId,
            }
          },
          svg: "room.svg",
          active: Object.keys(params).length === 3,
          disabled: Object.keys(params).length < 3
        }
      ]
    }
  }
  ,
})
</script>
<style scoped lang="scss">
@import "ParameterNav";
</style>