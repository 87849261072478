<template>
  <div>
    <div class="upbar">
      <div v-if="displayLogo" class="d-flex align-items-center justify-content-start navigation-item">
        <div class="header-button mr-2 ml-2">
          <b-button size="m" class="toggle-navbar-button" @click="toggleNavBar()">
              <b-icon icon="list" />
          </b-button>
        </div>
        <router-link :to="{name:'institutions.index'}"
                   class="institution-name-container h-100 text-center d-flex" :disabled="!displayLogo">
          <img class="bg-white rounded-circle sw-logo my-auto"
              src="../../assets/logo-superwyze-color.svg"/>
        </router-link>
      </div>
      <div class="navigation-item" v-else></div>
      <div class="upbar-logo col text-center my-auto justify-content-center">
        <img
            v-if="$router.currentRoute.name === 'institutions.index' || $router.currentRoute.name === 'institutions.create' || $router.currentRoute.name === 'institutions.edit'"
            src="../../assets/logo-superwyze-color.svg"/>
        <div class="w-100 justify-content-center" v-else-if="!displayNavBar">
          <div class="col-auto my-auto institution-label">
            <p class="my-auto">{{ name }}</p>
          </div>
          <div class="col-auto my-auto d-flex justify-content-center align-items-center">
            <inline-svg class="mr-1 main-route-logo" :src="mainRoute.logo"></inline-svg>
            <p class="my-auto">{{ $t(mainRoute.route) }}</p>
          </div>
        </div>
        <p v-else class="my-auto">{{ name }}</p>
      </div>
      <div class="my-auto navigation-item">
        <!-- <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right>
          <template #button-content>
            <img src="sw-studio-thermometry/src/assets/icons/AlerteNotification.svg"/>
            <b-badge v-if="alertCount > 0" variant="danger">{{ alertCount }}</b-badge>
          </template>
          <b-dropdown-item v-for="alert in alerts" :key="alert.key">{{ alert }}</b-dropdown-item>
        </b-dropdown> -->
        <font-awesome-icon v-if="!isFullScreen"
                          class="expand-icon fullscreen-icon"
                          :icon="expandIcon"
                          @click="goFullScreen"
                          size="lg"
        />
        <font-awesome-icon v-else
                          class="expand-icon fullscreen-icon"
                          :icon="compressIcon"
                          @click="goFullScreen"
                          size="lg"
        />
        
        <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right>
          <template #button-content>
            <img src="../../assets/user-logged-icon.svg"/>
          </template>
          <b-dropdown-item @click="goMySpace">{{ $t('user.my_space') }}</b-dropdown-item>
          <b-dropdown-item id="logout-button" @click="logout">{{ $t('user.deconnexion') }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="downbar" v-if="displayParameterNavBar() && displayNavBar">
      <template v-for="subRoute in subRoutesList">
        <router-link :key="subRoute.name"
                     v-if="userAccess && shouldDisplayLink(subRoute.name)"
                     :to="subRoute" tag="div" class="route-option"
                     :id="`${subRoute.name}`"
                     :class="{ 'active-header': isActiveRoute(subRoute) }"
                     >
          <inline-svg class="subRoute" :src="subRoute.meta.logo"></inline-svg>
          {{ $t(subRoute.name) }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
import {RouterLinkStub as route} from "@vue/test-utils";
import {toggleFullScreen} from "@/utils/common";
import { faExpand, faCompress } from '@fortawesome/free-solid-svg-icons'
import InlineSvg from "vue-inline-svg";

export default {
  name: "LocalisationView",
  components: {
    InlineSvg
  },
  data: function () {
    return {
      expandIcon : faExpand,
      compressIcon: faCompress,
      isFullScreen: false,
      selectedInstitution: {},
      currentRouteName: "",
      mainRoute: store.state.navigation.mainRoute,
      allInstitutions: [],
      exludeRouteName: [
        'institutions.index'
      ],
      excludeSubRoute: [
        'access.denied'
      ],
      name: "",
      displayLogo: true,
      alertCount: 0,
      alerts: [],
      displayNavBar: true
    };
  },
  created(){
    this.checkFullScreen();
    document.addEventListener('fullscreenchange', this.checkFullScreen);
    document.addEventListener('mozfullscreenchange', this.checkFullScreen);
    document.addEventListener('webkitfullscreenchange', this.checkFullScreen);
    document.addEventListener('msfullscreenchange', this.checkFullScreen);
  },
  mounted() {
    this.currentRouteName = this.$route.name;
    this.shouldDisplayLogo()
    this.updateNameFromLocalStorage()
  },
  beforeDestroy() {
    document.removeEventListener('fullscreenchange', this.checkFullScreen);
    document.removeEventListener('mozfullscreenchange', this.checkFullScreen);
    document.removeEventListener('webkitfullscreenchange', this.checkFullScreen);
    document.removeEventListener('msfullscreenchange', this.checkFullScreen);
  },
  watch: {
    $route(to, _) {
      this.updateNameFromLocalStorage();
      this.currentRouteName = to.name;
      this.shouldDisplayLogo()
    },
    'store.state.navigation.mainRoute'() {
      this.mainRoute = store.state.navigation.mainRoute;
    }
  },
  computed: {
    route() {
      return route
    },
    store() {
      return store
    },
    subRoutesList() {
      return this.getChildRoutes();
    },
    userAccess() {
      return this.store.state.user.access
    }
  },
  methods: {
    faExpand() {
      return faExpand
    },
    updateNameFromLocalStorage() {
      this.name = store.state.institution.institutionName;
    },
    displayParameterNavBar() {
      return !(this.currentRouteName === 'institutions.edit' || this.currentRouteName === 'institutions.create');
    },
    getChildRoutes() {
      const institutionRoutes = router.options.routes.filter(route => route.path.startsWith("/institutions/"));

      if (institutionRoutes.length !== 1 || this.exludeRouteName.includes(this.$route.name)) {
        return [];
      }

      const mainChildren = institutionRoutes[0].children;
      let childRoutes = [];
      for (let child of mainChildren) {
        if (child.path && child.name) {
          if (child.name === 'routes.names.thermometry') {
            if (this.store.state.institution.thermometry) {
              childRoutes.push(child);
            }
          } else if (!this.excludeSubRoute.includes(child.name) && !child.path.includes("/")) {
            childRoutes.push(child);
          }
        }

        if (Array.isArray(child.children) && child.children.length) {
          const subChildrenWithEmptyPath = child.children.filter(subChild => subChild.path === "" && !subChild.name.includes('parameters'));
          childRoutes = childRoutes.concat(subChildrenWithEmptyPath);
        }
      }

      return childRoutes;
    },
    getRouteOptionClass(route) {
      return this.isCurrentRoute(route) ? "selected" : "";
    },
    isCurrentRoute(route) {
      return route.name === this.currentRouteName;
    },
    isActiveRoute(route) {
      const basePath = this.$route.path.split('/')[3];
      return route.name === this.$route.name || this.$route.name.includes(route.name) || basePath === route.path;
    },
    shouldDisplayLogo() {
      const listOfRoutes = ['institutions.index', 'institutions.create', 'institutions.edit']
      this.displayLogo = !listOfRoutes.includes(this.$route.name);
    },
    shouldDisplayLink(routeName) {
      const access = this.userAccess[routeName.split('.').at(-1)]
      if (access) {
        return access['access']
      }
    },
    logout() {
      this.$router.push({name: "login"})
    },
    goMySpace() {
      this.$router.push({name: "myspace"})
    },
    goFullScreen(){
      this.isFullScreen = !this.isFullScreen
      toggleFullScreen();
    },
    checkFullScreen() {
      this.isFullScreen = document.fullscreenElement ||
                          document.mozFullScreenElement ||
                          document.webkitFullscreenElement ||
                          document.msFullscreenElement ? true : false;
    },
    toggleNavBar(){
      this.displayNavBar = !this.displayNavBar;
    }
  },
};
</script>

<style>
@import "@/components/navbar/NavBar.scss";
</style>
