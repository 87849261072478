const filtersModule = {
  state: {
    filtersArray: [],
    tagsFiltersArray: [],
    isChecked: []
  },
  mutations: {
    updateFiltersArray(state, newFiltersArray) {
      if(newFiltersArray.length > 0){
        state.filtersArray = newFiltersArray.filter(item => Object.keys(item));
      } else {
        state.filtersArray = [];
      }
    },
    updateTagsFiltersArray(state, newTagsFiltersArray) {
      state.tagsFiltersArray = newTagsFiltersArray.filter(item => Object.keys(item).length !== 0);
    },
    updateIsChecked(state, payload) {
      state.isChecked = payload;
    },
    resetFilters(state){
      state.filtersArray = [];
      state.tagsFiltersArray = [];
      state.isChecked = []
    }
  }
}

export default filtersModule;